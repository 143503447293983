import React, { createContext, useContext, useState } from 'react';
import ConfirmBox, { ConfirmBoxOptions } from './';

const ConfirmContext = createContext<((options: ConfirmBoxOptions) => any) | undefined>(undefined);

export const useConfirmBox = () => {
  const ctx = useContext(ConfirmContext);

  if (!ctx) {
    throw Error('The `useConfirmBox` hook must be called from a descendent of the `ConfirmBoxProvider`.');
  }
  return ctx;
};

export const ConfirmBoxProvider = ({ children }: any) => {
  const [confirmBoxState, setConfirmBoxState] = useState<ConfirmBoxOptions | null>(null);

  const openConfirmBox = (options: ConfirmBoxOptions) => {
    setConfirmBoxState(options);
  };

  const handleClose = () => {
    if (confirmBoxState && confirmBoxState.onClose) {
      confirmBoxState.onClose();
    }
    setConfirmBoxState(null);
  };

  const handleConfrim = () => {
    if (confirmBoxState && confirmBoxState.onConfirm) {
      confirmBoxState.onConfirm();
    }
    setConfirmBoxState(null);
  };

  return (
    <>
      <ConfirmContext.Provider value={openConfirmBox} children={children} />
      <ConfirmBox
        content=""
        title=""
        {...confirmBoxState}
        isOpen={Boolean(confirmBoxState)}
        onConfirm={handleConfrim}
        onClose={handleClose}
      />
    </>
  );
};
