import { useContext, useEffect } from 'react';
import { useMediaMatch } from 'liquify';
import { AppContext } from '../context/AppContext';

export const mediaRouteSwitcher = (config: any, exact: boolean, size: string) => {
  if (size === 'deskWide') {
    return exact && config.deskWide.exact ? config.deskWide.exact : config.deskWide.default;
  }
  if (size === 'desk') {
    return exact && config.desk.exact ? config.desk.exact : config.desk.default;
  }
  if (size === 'tablet') {
    return exact && config.tablet.exact ? config.tablet.exact : config.tablet.default;
  }
  return exact && config.mobile.exact ? config.mobile.exact : config.mobile.default;
};

export function MediaComponent(props: any) {
  // const [size, setSize] = useState<string | null>(null);
  const { isDesk, isDeskWide, isTablet, isTabletLandscape } = useMediaMatch();
  const { screen, setScreen } = useContext<any>(AppContext);

  useEffect(() => {
    let s = 'mobile';
    if (isDeskWide) {
      s = 'deskWide';
    }
    if (isDesk) {
      s = 'desk';
    }
    if (isTablet || isTabletLandscape) {
      s = 'tablet';
    }
    setScreen(s);
  }, [isDesk, isDeskWide, isTablet, isTabletLandscape]);

  return props.children(screen);
}
