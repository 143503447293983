export const COLORS = {
  bat: '#4D80F1',
  bumblebee: '#FEC400',
  cable: '#F7F9FB',
  cat: '#4279F3',
  dead: '#31394D',
  drax: '#B8B8B8',
  flash: '#FE8900',
  groot: '#ECEFF3',
  hulk: '#00BB7F',
  iron: '#748AA1',
  joker: '#EBF1FE',
  killmonger: '#0979be',
  nova: '#D5E1FC',
  panther: '#064E79', //'#0A2946',
  phantom: '#B558F6',
  punisher: '#F2F5FA',
  robin: '#779FF8',
  shadow: '#F4F4F4',
  silverswan: '#061320',
  super: '#FFFFFF',
  strange: 'rgba(0, 0, 0, 0.2)',
  storm: '#C0C4CA',
  thor: '#E40134',
  wonder: '#000000',
};
