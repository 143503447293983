import styled from 'styled-components';

export const DialogBody = styled.div`
  flex: 1 1 auto;
  margin-bottom: 20px;
  line-height: 18px;
`;

export const DialogFooter = styled.div`
  flex: 0 0 auto;
  margin: 0 20px;
`;

export const DialogActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    margin-left: 10px;
  }
`;
