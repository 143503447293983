import { GrayScale, IThemeInterface } from 'liquify';

export const TFSTheme: IThemeInterface = {
  forms: {
    input: {
      background: GrayScale.WHITE,
      disabled: 'rgba(206,217,224,.5)',
      placeholder: 'rgba(206,217,224,.5)',
      outline: 'rgba(96, 125, 139, .3)',
      focus: 'rgba(96, 125, 139, .3)',
    },
  },
  brand: {
    rgb: {
      primary: '53, 29, 200',
      secondary: '23,27,37',
      warning: '255, 133, 26',
      info: '35, 90, 246',
      success: '109, 222, 123',
      danger: '251, 81, 97',
    },
    primary: {
      lightest: '#ebe9fc',
      light: '#9d90ef',
      base: '#331CBF',
      dark: '#2a179c',
      darker: '#120a43',
    },
    secondary: {
      lightest: '#565960',
      light: '#2C2F38',
      base: '#171b25',
      dark: '#11141b',
      darker: '#050507',
    },
    danger: {
      lightest: '#fee6e8',
      light: '#fc838e',
      base: '#FA3A4C',
      dark: '#ae0414',
      darker: '#4b0209',
    },
    info: {
      lightest: '#e7edfe',
      light: '#85a3fa',
      base: '#1751F5',
      dark: '#85a3fa',
      darker: '#05247a',
    },
    warning: {
      lightest: '#fff1e5',
      light: '##ffbb80',
      base: '#FF8F2C',
      dark: '#b35400',
      darker: '#4d2400',
    },
    success: {
      lightest: '#eafaec',
      light: '#97e7a1',
      base: '#2DC740',
      dark: '##21922f',
      darker: '##e3e14',
    },
  },
  text: {
    default: GrayScale.BLACK,
    muted: GrayScale.GRAY1,
    inverted: GrayScale.WHITE,
    disabled: 'rgba(67, 90, 111, 0.3)',
  },
  background: {
    default: GrayScale.LIGHT_GRAY5,
    dark: GrayScale.DARK_GRAY1,
    light: GrayScale.WHITE,
  },
  navigation: {
    text: {
      default: GrayScale.GRAY3,
      hover: GrayScale.WHITE,
    },
  },
  components: {
    highlighter: '#FCD07F',
  },
};
