/**
 *
 * Wrapper Web Component
 *
 */

import React from 'react';
import { Container } from './styles';

type XPlacement = 'left' | 'right' | 'center';
type YPlacement = 'top' | 'bottom' | 'center';
export interface IProps {
  /* padding */
  p?: number;
  /* padding left and right */
  px?: number;
  /* padding top and and bottom */
  py?: number;
  children: any;
  xAlign?: XPlacement;
  yAlign?: YPlacement;
  bgImg?: any;
  className?: string;
}

export default (props: IProps) => {
  const { children, ...otherProps } = props;
  return <Container {...otherProps}>{children}</Container>;
};
