/**
 *
 * Login Web Component
 *
 */

import { Button, Card, Intent } from 'liquify';
import { env } from '@analytics-trade-monitoring/common';
import * as React from 'react';
import Wrapper from '../Wrapper';
import { Center, Spacer, Container, ButtonWrapper, Subtitle } from './styles';
import Brand from '../Brand';

const Login = () => {
  const handleClick = () => {
    (window as any).location.href = env.LOGIN_URL;
  };

  return (
    <Wrapper xAlign="center" yAlign="center" data-testid="Login" className="login-bg">
      <Container>
        <Card width="400px" elevation={4}>
          <Center>
            <Brand width={220} />
          </Center>
          <Subtitle>Intelligent Swap Trade Tracking and Review</Subtitle>
          <Spacer>
            <ButtonWrapper>
              <Button data-testid="loginButton" onClick={handleClick} intent={Intent.PRIMARY} size="large" fullWidth>
                Login
              </Button>
            </ButtonWrapper>
          </Spacer>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default Login;
