import { h1, h2, h3, h4, GrayScale } from 'liquify';
import { isFirefox, isIE } from 'react-device-detect';
import { createGlobalStyle } from 'styled-components';
import { COLORS } from './styles/theme';
const fireFoxScrollFix = isFirefox ? 'min-width:0;min-height:0;' : '';

const GlobalStyle = createGlobalStyle<any>`

* {
  scrollbar-width: thin;
  scrollbar-color:   rgba(0,0,0,.55) transparent;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
	background-color:transparent;
}

::-webkit-scrollbar{
  width: 7px;
  height: 7px;
	background-color: :transparent;
}

::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background-color: rgba(0,0,0,.55);
}

  html,
  body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    color:${GrayScale.DARK_GRAY1};
    font-family: 'Roboto', sans-serif;
    line-height:1.4;

  }
  h1 { ${h1} }
  h2 { ${h2} }
  h3 { ${h3} }
  h4 { ${h4} }
  * {
    box-sizing: border-box;
    ${isIE ? '-ms-overflow-style: none;' : ''}
  }
  #app {
    background:${COLORS.punisher};
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    max-height: 100%;
    flex-direction: column;
  }
  p,
  label {
    margin: 0;
  }
  #app > [data-reactroot] {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 0;
    ${fireFoxScrollFix}
  }
  * {
    box-sizing: border-box;
  }
  .text-red{
    color:${(props) => props.theme.brand.danger.base};
  }

  .text-green{
    color:${(props) => props.theme.brand.success.base};
  }
  [data-simplebar] {
    flex: 1 1 0;
  }

  .withElipsis{ 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .overflow-hidden{
    overflow:hidden;
  }
`;

export default GlobalStyle;
