import React from 'react';
import { Theme, AlertsProvider } from 'liquify';
import { ApiProvider } from '@analytics-trade-monitoring/api';
import { LangageProvider } from '@analytics-trade-monitoring/common';
import GlobalStyle from '../../global-styles';
import { AppContextProvider } from '../../context/AppContext';
import { RoutesManager } from '../../routes';
import { ConfirmBoxProvider } from '../../components/ConfimBox';
import { TFSTheme } from '../../styles/tfs-theme';
const App: React.FC<{}> = (props) => (
  <LangageProvider>
    <ApiProvider>
      <Theme theme={TFSTheme}>
        <GlobalStyle />
        <AppContextProvider>
          <AlertsProvider>
            <ConfirmBoxProvider>
              <RoutesManager />
            </ConfirmBoxProvider>
          </AlertsProvider>
        </AppContextProvider>
      </Theme>
    </ApiProvider>
  </LangageProvider>
);
export default App;
