import queryString from 'query-string';
import { matchPath } from 'react-router-dom';
/**
 * Update Query
 */
export const updateQuery = (insert: any = {}, pathname: string, query: string = '', router: any) => {
  const obj = Object.assign(queryString.parse(query), insert);
  const params = Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
  router.push(`${pathname}?${params}`);
};

/**
 * check match route on any component
 */
export const getMatchParams = (pathname: string, pathMatch: string) => {
  const matchProfile = matchPath(pathname, {
    path: pathMatch,
  });
  return matchProfile || {};
};
