/**
 *
 * ConfirmBox Web Component
 *
 */

import React from 'react';
import { Dialog, Button, Intent, Icons } from 'liquify';
import { DialogFooter, DialogBody, DialogActionButtons } from './styles';

export interface ConfirmBoxOptions {
  /** function to execute when clicking on confirm button */
  onConfirm: () => void;
  /** function to execute when clicking on cancel button */
  onClose?: () => void;
  /** confirm box type  */
  intent?: Intent;
  /** confirm box title  */
  title: string | null;
  /** confirm box content  */
  content: React.ReactNode;
  /** confirm button text @default `Ok`  */
  confirmText?: string;
  /** cancel button text @default `Cancel`  */
  canceltext?: string;
}

export interface ConfirmBoxProps extends ConfirmBoxOptions {
  /** wether the dialog is open or not  */
  isOpen?: boolean;
}

export const ConfirmBox: React.FC<ConfirmBoxProps> = (props) => {
  const {
    isOpen,
    onConfirm,
    onClose,
    intent = Intent.PRIMARY,
    title,
    content,
    confirmText = 'Ok',
    canceltext = 'Cancel',
  } = props;

  const getIcon = (): Icons => {
    if (intent === Intent.DANGER || intent === Intent.INFO) {
      return 'warning';
    } else if (intent === Intent.SUCCESS) {
      return 'tick';
    }
    return 'info';
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog
      style={{ paddingBottom: 0 }}
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      icon={getIcon()}
      testId="confirmBoxContainer"
      zIndex={998}
    >
      <DialogBody data-testid="confirmboxBody">{content}</DialogBody>
      <DialogFooter>
        <DialogActionButtons>
          <Button
            text={canceltext}
            appearance="minimal"
            onClick={handleClose}
            data-testid="confirmBoxCloseButton"
            shape="rounded"
          />
          <Button
            text={confirmText}
            intent={intent}
            onClick={onConfirm}
            data-testid="confirmBoxConfirmationButton"
            shape="rounded"
          />
        </DialogActionButtons>
      </DialogFooter>
    </Dialog>
  );
};
export * from './context';
export default ConfirmBox;
