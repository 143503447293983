import { spacing, GrayScale } from 'liquify';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  padding: ${spacing(12)};
`;
export const Spacer = styled.div`
  padding: ${spacing(10)} 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Center = styled.div`
  padding: ${spacing(10)} 0 ${spacing(6)} 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 150px;
`;

export const Subtitle = styled.div`
  color: ${GrayScale.GRAY1};
`;
