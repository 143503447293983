import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MediaComponent, mediaRouteSwitcher } from '../utils';
import loadable from '@loadable/component';

const Navigation = loadable(() => import('../containers/NavContainer'));
const PbiReport = loadable(() => import('../containers/PbiReport'));

const getPageComponentFromRouteParam = (param: any) => {
  const routeCompMapper: any = {
    report: PbiReport,
    default: () => null,
  };
  return routeCompMapper[param] || routeCompMapper.default;
};

const mainRoute = (location: any, size: string) =>
  mediaRouteSwitcher(
    {
      mobile: {
        exact: <Navigation />,
      },
      tablet: {
        default: <Navigation />,
      },
      desk: {
        default: <Navigation />,
      },
      deskWide: {
        default: <Navigation />,
      },
    },
    location.match.isExact,
    size,
  );

const PageRoute = (location: any, size: string) => {
  const Comp = getPageComponentFromRouteParam(location.match.params.page);
  const reportId = location.match.params.reportId;
  return mediaRouteSwitcher(
    {
      mobile: {
        default: <Comp reportId={reportId} />,
        exact: <Comp reportId={reportId} />,
      },
      tablet: {
        default: <Comp reportId={reportId} />,
      },
      desk: {
        default: <Comp reportId={reportId} />,
      },
      deskWide: {
        default: <Comp reportId={reportId} />,
      },
    },
    location.match.isExact,
    size,
  );
};

export const RoutesManager = () => {
  return (
    <MediaComponent>
      {(size: string) => (
        <Router>
          <Route path="/" render={(location) => mainRoute(location, size)} />
          <Route path="/:page/:reportId" render={(location) => PageRoute(location, size)} />
        </Router>
      )}
    </MediaComponent>
  );
};
