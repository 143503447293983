import React, { createContext, useRef, useState } from 'react';
import { AuthConfigData, EmbedData, EventData, getUserDetails, UserData } from '@analytics-trade-monitoring/api';
import { requestSwitch } from '@analytics-trade-monitoring/common';
import { Button, Loading, Offline, ComponentState } from 'liquify';
import Login from '../components/Login';
import Wrapper from '../components/Wrapper';

interface AppConfigData {
  searchedModelId: number;
}

interface ProviderProps {
  screen?: string;
  appConfig?: AppConfigData;
  embedConfig?: EmbedData;
  eventConfig?: EventData;
  children: React.ReactNode;
}
export interface AppContextProps {
  appConfig: AppConfigData;
  currentReport: string;
  setCurrentReport: any;
  setAppConfig: any;
  embedConfig: EmbedData;
  setEmbedConfig: any;
  eventConfig: EventData;
  setEventConfig: any;
  refetchConfig: any;
  canEditSwap: boolean;
  setCanEditSwap: any;
  refetchSwapEditStatus: any;
  screen: string;
  setScreen: any;
  user: UserData;
  authConfig: AuthConfigData;
  reportRef: any;
}

export const Context = createContext<Partial<AppContextProps>>({});

const renderLoading = () => <Loading data-testid="mainAppLoading" />;
const renderError = (action: any) => () => (
  <ComponentState
    data-testid="mainAppErrorComponent"
    title="Error while loading data!"
    icon="warning"
    action={<Button icon="refresh" text="Refresh page" onClick={() => action()} />}
  />
);
const renderNoData = () => <ComponentState title="No Data Available" data-testid="mainAppNoDataComponent" />;
const renderOffline = () => <Offline />;

const renderLogin = () => (
  <Wrapper>
    <Login />
  </Wrapper>
);

export const Provider = (props: ProviderProps) => {
  // Initial values are obtained from the props
  const {
    screen: initialScreen,
    appConfig: initialAppConfig,
    embedConfig: initialEmbedConfig,
    eventConfig: initialEventConfig,
    children,
  } = props;

  const { data, error, refetch, networkStatus } = getUserDetails();

  const [appConfig, setAppConfig] = useState(initialAppConfig);
  const [embedConfig, setEmbedConfig] = useState(initialEmbedConfig);
  const [eventConfig, setEventConfig] = useState(initialEventConfig);
  const [canEditSwap, setCanEditSwap] = useState<boolean>();
  const [currentReport, setCurrentReport] = useState('');
  const [screen, setScreen] = useState(initialScreen);
  const refetchConfig = useRef();
  const refetchSwapEditStatus = useRef();
  const reportRef = useRef();

  const renderMain = () => {
    // Make the context object:
    const appContext = {
      appConfig,
      currentReport,
      setCurrentReport,
      setAppConfig,
      embedConfig,
      setEmbedConfig,
      eventConfig,
      setEventConfig,
      refetchConfig,
      canEditSwap,
      setCanEditSwap,
      refetchSwapEditStatus,
      screen,
      setScreen,
      user: data?.user,
      authConfig: data?.getAuthConfig,
      reportRef,
    };
    return <Context.Provider value={appContext}>{children}</Context.Provider>;
  };
  // pass the value in provider and return
  return requestSwitch(
    {
      loading: renderLoading,
      offline: renderOffline,
      error: renderError(refetch),
      nodata: renderNoData,
      render: renderMain,
      onUnauthorized: renderLogin,
    },
    { data, error, networkStatus },
    ['user'],
  );
};

export const { Consumer } = Context;

export { Context as AppContext, Provider as AppContextProvider, Consumer as AppContextConsumer };
