import React from 'react';
import styled from 'styled-components';
import { round } from '@analytics-trade-monitoring/common';
import _find from 'lodash.find';

type display = 'currency' | 'percent' | undefined;
export interface NumberFormatterProps {
  /** the value to format */
  value: number;
  /** currency code (required for currency formatter) */
  currency?: string;
  /** minimum digits to show for decimal values
   * @default 2
   * */
  minimumFraction?: number;
  /** maximum digits to show for decimal values
   * @default 2
   * */
  maximumFraction?: number;
  /** formatting style for negative number
   * @default 'parenthesis'
   * */
  negativeFormat?: 'sign' | 'parenthesis';
  /** show negative number in red color
   * @default true
   * */
  withNegativeStyle?: boolean;
  /** show positive number in green color
   * @default false
   * */
  withPositiveStyle?: boolean;
  /** region/locale
   * @default 'en-US'
   * */
  locale?: string;
  /** return formatted value in html format
   * @default false
   * */
  html?: boolean;
  /** remove trailing zeros from decimal values
   * @default true
   * */
  truncateZeros?: boolean;
  /** display big numbers in friendly format (1000 => 1K)
   * @default false
   * */
  compact?: boolean;
}

const NegativeNumberStyle = styled.span<any>`
  color: ${(props) => props.theme.brand.danger.base};
`;

const PositiveNumberStyle = styled.span<any>`
  color: ${(props) => props.theme.brand.success.base};
`;

const getSign = (parts: any, display: display) => {
  const sign = display === 'currency' ? 'currency' : display === 'percent' ? 'percentSign' : '';
  if (sign !== '' && parts && parts.length > 0) {
    return _find(parts, { type: sign }).value;
  }
  return '';
};
export const prettyBignumbers = (value: number, sign = '') => {
  let newValue = Math.abs(value);
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum += 1;
  }
  // newValue = newValue > 0 ? newValue.toPrecision(3) : newValue;
  newValue = value < 0 ? -newValue : newValue;
  return `${sign}${newValue}${suffixes[suffixNum]}`;
};

// this is a workaround for all browsers except chrome (this will be removed when all browser support currencySign option)
const NegativeFormatter = (value: string, withParenthesis = true, withStyle: boolean, html = false) => {
  const signPosition = value.indexOf('-');
  //remove minus sign and add parenthesis
  const newValue =
    withParenthesis && signPosition !== -1 ? `(${value.replace(value.charAt(signPosition), '')})` : value;
  const styledValue = html ? (
    `<span class="text-red">${newValue}</span>`
  ) : (
    <NegativeNumberStyle>{newValue}</NegativeNumberStyle>
  );

  return withStyle ? styledValue : newValue;
};

const PositivFormatter = (value: string, withStyle: boolean, html = false) => {
  const styledValue = html ? (
    `<span class="text-green">${value}</span>`
  ) : (
    <PositiveNumberStyle>{value}</PositiveNumberStyle>
  );
  return withStyle ? styledValue : value;
};

export const numberFormatter = (params: NumberFormatterProps, display?: display) => {
  const {
    value,
    currency,
    minimumFraction = 2,
    maximumFraction = 2,
    locale = 'en-US',
    negativeFormat = 'parenthesis',
    withNegativeStyle = true,
    withPositiveStyle = false,
    html = false,
    truncateZeros = true,
    compact = false,
  } = params;

  const roundValue = round(value, maximumFraction + 2);
  let hasTrailingZeros = false;
  let parts: any;

  if (truncateZeros) {
    hasTrailingZeros = round(Math.abs(roundValue) * 100, maximumFraction) % 1 === 0;
  }
  const config: any = {
    minimumFractionDigits: hasTrailingZeros ? 0 : minimumFraction,
    maximumFractionDigits: hasTrailingZeros ? 0 : maximumFraction,
  };
  if (display) {
    config.style = display;
  }
  if (display === 'currency') {
    config.currencySign = negativeFormat === 'parenthesis' ? 'accounting' : 'standard';
    config.currency = currency;
  }

  //format the number
  let formattedValue = new Intl.NumberFormat(locale, config).format(value);
  //if compact get the formatted value as parts
  if (compact) {
    parts = new Intl.NumberFormat(locale, config).formatToParts(value);
    formattedValue = prettyBignumbers(value, getSign(parts, display));
  }

  //check if the value is equal to -0 and return it without minus sign
  return value < 0
    ? NegativeFormatter(formattedValue, negativeFormat === 'parenthesis', withNegativeStyle, html)
    : Math.abs(value) === 0
    ? formattedValue
    : PositivFormatter(formattedValue, withPositiveStyle, html);
};

export const currencyFormatter = (params: NumberFormatterProps) => {
  const {
    value,
    currency = 'USD',
    minimumFraction = 2,
    maximumFraction = 2,
    withNegativeStyle = true,
    negativeFormat,
    withPositiveStyle = false,
    html = false,
    truncateZeros = true,
    compact = false,
  } = params;
  let currencyVerified = currency;
  // there are some cases where position have empty cuurency
  if (currency === '') {
    currencyVerified = 'USD';
  }
  return numberFormatter(
    {
      value,
      currency: currencyVerified,
      minimumFraction,
      maximumFraction,
      withNegativeStyle,
      withPositiveStyle,
      html,
      truncateZeros,
      negativeFormat,
      compact,
    },
    'currency',
  );
};

export const percentFormatter = (params: NumberFormatterProps) => {
  const {
    value,
    minimumFraction = 2,
    maximumFraction = 2,
    withNegativeStyle = true,
    withPositiveStyle = false,
    negativeFormat,
    html = false,
    truncateZeros = true,
    compact = false,
  } = params;

  return numberFormatter(
    {
      value,
      negativeFormat,
      minimumFraction,
      maximumFraction,
      withNegativeStyle,
      withPositiveStyle,
      html,
      truncateZeros,
      compact,
    },
    'percent',
  );
};
