export const redirectToLoginPage = () => window.location.reload();

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export * from './mediaRouteSwitcher';

export * from './queryUtils';

export * from './logout';

export * from './numberFormatter';

export * from './mapSortModel';
