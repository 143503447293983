/**
 *
 * Branding Web Component
 *
 */

import * as React from 'react';

interface IProps {
  percent?: boolean;
  width: number;
  height?: number;
  fill?: string;
}

export default (props: IProps) => {
  const unit = props.percent ? '%' : 'px';
  const svgProps: any = {
    width: `${props.width}${unit}`,
  };
  if (props.height) {
    svgProps.height = `${props.height}${unit}`;
  }
  return (
    <svg {...svgProps} viewBox="0 0 206 42">
      <title>logo</title>
      <g id="logo-2">
        <g id="TFS-REVIEWS">
          <g>
            <path d="M69.87,13.9H64.4V29H61.86V13.9h-5.5V11.77H69.87Z" fill="#181d28" />
            <path d="M74.92,13.84v5.77h6.91v2.08H74.92V29H72.36V11.77H83v2.08Z" fill="#181d28" />
            <path
              d="M94.51,14.58a1,1,0,0,1-.25.3.55.55,0,0,1-.34.1.88.88,0,0,1-.49-.2c-.18-.13-.41-.28-.69-.44a5.32,5.32,0,0,0-1-.44,4.27,4.27,0,0,0-1.38-.2,4,4,0,0,0-1.33.2,2.79,2.79,0,0,0-1,.54,2.25,2.25,0,0,0-.58.8,2.51,2.51,0,0,0-.2,1,1.82,1.82,0,0,0,.35,1.15,3,3,0,0,0,.93.78,7.25,7.25,0,0,0,1.31.56l1.5.5a15.3,15.3,0,0,1,1.5.59,5.29,5.29,0,0,1,1.31.85,3.89,3.89,0,0,1,.93,1.25,4.16,4.16,0,0,1,.35,1.81,5.8,5.8,0,0,1-.39,2.14A5,5,0,0,1,94,27.6a5.26,5.26,0,0,1-1.83,1.17,6.71,6.71,0,0,1-2.47.43,7.95,7.95,0,0,1-3.08-.62,7.46,7.46,0,0,1-1.3-.71,6.42,6.42,0,0,1-1.09-.93l.73-1.22a1,1,0,0,1,.26-.24.62.62,0,0,1,.34-.1,1,1,0,0,1,.58.26q.33.27.82.58a6.3,6.3,0,0,0,1.16.58,4.54,4.54,0,0,0,1.65.26,4.44,4.44,0,0,0,1.42-.21,2.92,2.92,0,0,0,1.05-.6,2.61,2.61,0,0,0,.65-.94,3.18,3.18,0,0,0,.23-1.22,2.05,2.05,0,0,0-.35-1.24,2.83,2.83,0,0,0-.92-.8,6.81,6.81,0,0,0-1.31-.55L89,21a15.06,15.06,0,0,1-1.5-.57,4.88,4.88,0,0,1-1.31-.85,3.83,3.83,0,0,1-.92-1.31,4.78,4.78,0,0,1-.35-1.94,4.6,4.6,0,0,1,.36-1.79,4.53,4.53,0,0,1,1-1.53A5.17,5.17,0,0,1,88,12a6.19,6.19,0,0,1,2.3-.4A7.48,7.48,0,0,1,93,12a6.18,6.18,0,0,1,2.12,1.33Z"
              fill="#181d28"
            />
            <path
              d="M117.63,29h-2.28a1.06,1.06,0,0,1-1-.54l-4.26-6a1.28,1.28,0,0,0-.45-.43,1.6,1.6,0,0,0-.72-.13h-1.67V29h-2.56V11.77h5a10.59,10.59,0,0,1,2.87.34,5.57,5.57,0,0,1,2,1,3.87,3.87,0,0,1,1.16,1.52,5,5,0,0,1,.38,2,5.12,5.12,0,0,1-.28,1.69,4.43,4.43,0,0,1-.81,1.42,5.11,5.11,0,0,1-1.3,1.09,6.39,6.39,0,0,1-1.74.7,2.63,2.63,0,0,1,.83.8Zm-8-8.93a5.69,5.69,0,0,0,1.73-.24,3.53,3.53,0,0,0,1.25-.68,2.79,2.79,0,0,0,.76-1,3.46,3.46,0,0,0,.25-1.34,2.66,2.66,0,0,0-1-2.24,4.76,4.76,0,0,0-2.93-.76h-2.41v6.3Z"
              fill="#181d28"
            />
            <path d="M130.53,26.92V29H119.84V11.77h10.68v2.08h-8.11V19.3h6.53v2h-6.53v5.62Z" fill="#181d28" />
            <path
              d="M147.89,11.77l-7,17.23H138.6l-7-17.23h2a.85.85,0,0,1,.55.17,1.06,1.06,0,0,1,.32.43l4.66,11.71q.17.43.33.95t.29,1.08q.12-.56.26-1.08a9.73,9.73,0,0,1,.31-.95L145,12.37a.93.93,0,0,1,.31-.42.84.84,0,0,1,.55-.18Z"
              fill="#181d28"
            />
            <path d="M152.61,29H150V11.77h2.57Z" fill="#181d28" />
            <path d="M167.55,26.92V29H156.86V11.77h10.68v2.08h-8.11V19.3H166v2h-6.53v5.62Z" fill="#181d28" />
            <path
              d="M193.21,11.77,187.83,29h-2.3l-4.2-12.78a6.08,6.08,0,0,1-.25-.92q-.13.55-.25.92L176.57,29h-2.29l-5.38-17.23H171a1,1,0,0,1,.56.16.76.76,0,0,1,.3.44l3.4,11.53c.06.22.12.47.17.73l.16.83c.06-.29.11-.56.17-.83a6.47,6.47,0,0,1,.21-.73l3.89-11.53a.84.84,0,0,1,.31-.42.89.89,0,0,1,.56-.18h.73a.88.88,0,0,1,.55.17,1,1,0,0,1,.31.43l3.88,11.53a13,13,0,0,1,.38,1.5q.14-.83.31-1.5l3.41-11.53a.75.75,0,0,1,.3-.42.91.91,0,0,1,.56-.18Z"
              fill="#181d28"
            />
            <path
              d="M204.41,14.58a1,1,0,0,1-.25.3.55.55,0,0,1-.34.1.88.88,0,0,1-.49-.2c-.18-.13-.41-.28-.69-.44a5.29,5.29,0,0,0-1-.44,4.27,4.27,0,0,0-1.38-.2,4,4,0,0,0-1.33.2,2.79,2.79,0,0,0-1,.54,2.27,2.27,0,0,0-.58.8,2.52,2.52,0,0,0-.2,1,1.82,1.82,0,0,0,.35,1.15,3.05,3.05,0,0,0,.93.78,7.29,7.29,0,0,0,1.31.56l1.5.5a15.3,15.3,0,0,1,1.5.59,5.29,5.29,0,0,1,1.31.85,3.91,3.91,0,0,1,.93,1.25,4.16,4.16,0,0,1,.35,1.81,5.79,5.79,0,0,1-.39,2.14,5,5,0,0,1-1.13,1.74A5.26,5.26,0,0,1,202,28.77a6.71,6.71,0,0,1-2.47.43,8,8,0,0,1-3.08-.62,7.46,7.46,0,0,1-1.3-.71,6.42,6.42,0,0,1-1.09-.93l.73-1.22a1,1,0,0,1,.26-.24.62.62,0,0,1,.34-.1,1,1,0,0,1,.58.26q.33.27.82.58a6.33,6.33,0,0,0,1.16.58,4.54,4.54,0,0,0,1.65.26,4.44,4.44,0,0,0,1.42-.21,2.93,2.93,0,0,0,1.05-.6,2.61,2.61,0,0,0,.65-.94,3.19,3.19,0,0,0,.23-1.22,2,2,0,0,0-.35-1.24,2.84,2.84,0,0,0-.92-.8,6.82,6.82,0,0,0-1.31-.55l-1.5-.47a15.15,15.15,0,0,1-1.5-.57,4.87,4.87,0,0,1-1.31-.85,3.83,3.83,0,0,1-.92-1.31,4.79,4.79,0,0,1-.35-1.94,4.6,4.6,0,0,1,.36-1.79,4.53,4.53,0,0,1,1-1.53,5.18,5.18,0,0,1,1.7-1.07,6.19,6.19,0,0,1,2.3-.4,7.47,7.47,0,0,1,2.67.46A6.19,6.19,0,0,1,205,13.36Z"
              fill="#181d28"
            />
          </g>
        </g>
        <g id="monitor-icon_2" data-name="monitor-icon 2">
          <path
            id="_Path_"
            data-name="&lt;Path&gt;"
            d="M4.18,0A4.2,4.2,0,0,0,0,4.19V37.81A4.2,4.2,0,0,0,4.18,42H41.82A4.2,4.2,0,0,0,46,37.81V4.19A4.2,4.2,0,0,0,41.82,0H4.18Z"
            fill="#202d8a"
          />
          <path
            id="_Path_2"
            data-name="&lt;Path&gt;"
            d="M4.18.07A4.13,4.13,0,0,0,0,4.23v31.4a4.13,4.13,0,0,0,4.18,4.16H41.82A4.13,4.13,0,0,0,46,35.63V4.16A4.13,4.13,0,0,0,41.82,0H4.18Z"
            fill="#331cbf"
          />
          <polygon
            id="_Path_3"
            data-name="&lt;Path&gt;"
            points="18.84 5.53 13.48 18.27 2.19 18.27 2.19 20.43 14.68 20.43 15.25 20.43 18.84 11.94 21.52 18.27 27.73 33.16 33.08 20.43 43.81 20.43 43.81 18.27 34.01 18.27 31.32 18.27 27.73 26.83 24.2 18.27 18.84 5.53"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
