import styled from 'styled-components';
import { spacing } from 'liquify';
import { IProps } from './';

const transformPlacement = (p: string) => {
  switch (p) {
    case 'top' || 'left':
      return 'flex-start';
    case 'bottom' || 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

export const Container = styled.div<IProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => (props.p ? `padding:${spacing(props.p)};` : '')}
  ${(props) => (props.py ? `padding-top:${spacing(props.py)};padding-bottom:${spacing(props.py)};` : '')}
  ${(props) => (props.px ? `padding-left:${spacing(props.px)};padding-right:${spacing(props.px)};` : '')}
  ${(props) => (props.xAlign ? `justify-content:${transformPlacement(props.xAlign)};` : '')}
  ${(props) => (props.yAlign ? `align-items:${transformPlacement(props.yAlign)};` : '')}
    ${(props) => (props.bgImg ? `background:url(${props.bgImg});` : '')}
    background-size: cover;
`;
