import { env } from '@analytics-trade-monitoring/common';

export const onLogoutClick = () => {
  sessionStorage.clear();
  const f = document.createElement('form');
  f.setAttribute('method', 'post');
  f.setAttribute('action', env.LOGOUT_URL);
  document.getElementsByTagName('body')[0].appendChild(f);
  f.submit();
};

export const onChangePassword = (userId: number, changepasswordUrl: string) => {
  const id = userId.toString();
  const f = document.createElement('form');
  f.setAttribute('method', 'get');
  f.setAttribute('action', changepasswordUrl);

  const returnUrlField = document.createElement('input');
  returnUrlField.type = 'hidden';
  returnUrlField.name = 'returnUrl';
  returnUrlField.value = f.baseURI;
  f.appendChild(returnUrlField);

  const logoutUrlField = document.createElement('input');
  logoutUrlField.type = 'hidden';
  logoutUrlField.name = 'logoutUrl';
  logoutUrlField.value = window.location.origin + '/logout';
  f.appendChild(logoutUrlField);

  const usernameField = document.createElement('input');
  usernameField.type = 'hidden';
  usernameField.name = 'username';
  usernameField.value = id.includes('\\') ? id.split('\\')[1].toLowerCase() : id;
  f.appendChild(usernameField);

  const allowRedirectField = document.createElement('input');
  usernameField.type = 'hidden';
  usernameField.name = 'allowRedirect';
  usernameField.value = 'true';
  f.appendChild(allowRedirectField);

  document.getElementsByTagName('body')[0].appendChild(f);
  f.submit();
};
